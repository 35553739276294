<template>
  <div class="profile">
      <v-row no-gutters class="ma-5">
          <v-expansion-panels v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header>Datos de usuarios</v-expansion-panel-header>
                <v-expansion-panel-content>
                        <v-col cols="12">
                            <userSection :formUser="formUser" />
                        </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>Datos Fiscales</v-expansion-panel-header>
                <v-expansion-panel-content>
                        <v-col cols="12">
                            <fiscal :form="form" @editContact="showForm" />
                        </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>Usuarios Invitados</v-expansion-panel-header>
                <v-expansion-panel-content>
                        <v-col cols="12">
                            <subUser :devicesAll="formUser.devices" />
                        </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    metaInfo: { title: 'Profile' },
    components: {
        userSection:()=> import('./sections/profile/user'),
        fiscal: () => import('./sections/profile/fiscal'),
        subUser: () => import('./sections/profile/subUser'),
    },
    data:()=>({
        form:{
            name:"",
            rfc:"",
            address:"",
            address1:"",
            country:"",
            state:"",
            city:"",
            zip:""
        },
        formUser:{
            devices:[]
        }
        ,sForm:false,
        panel:0
    }),
    mounted(){
        var profile = this.$route.params?.id || this.$store.state.user.id;
        this.loadProfile(profile);    
    },
    methods:{
        ...mapActions(['getProfile']),

        loadProfile(id){
            this.getProfile(id).then(res => {
                
                this.formUser = res.data.data;
                //console.log(this.formUser);
                this.form = res.data.data.contact||this.form;
            });
        },
        showForm(){
            var profile = this.$route.params?.id || this.$store.state.user.id;
            this.loadProfile(profile);
        }
    }
}
</script>

<style>

</style>